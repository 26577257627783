import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet-async";

const Wrapper = ({ children, doc }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href="https://emails.livesession.io/logo_favicon.png" />
        {process.env.CURRENT_ENV === "labs" ? <meta name="robots" content="noindex" /> : null}
        <meta name="title" content={site.siteMetadata.title} />
        <meta name="description" content={site.siteMetadata.description} />
        <meta property="og:title" content="LiveSession Developers - Docs" />
        <meta
          property="og:description"
          content="The LiveSession API gives developers access to the features via a JavaScript API, Rest API and Webhooks."
        />
        <meta name="twitter:card" content="website" />
        <meta name="twitter:title" content="LiveSession Developers - Docs" />
        <meta
          name="twitter:description"
          content="The LiveSession API gives developers access to the features via a JavaScript API, Rest API and Webhooks."
        />
      </Helmet>
      {children}
    </>
  );
};
export default Wrapper;
