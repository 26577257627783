import { media } from "~theme/breakpoints";

export const main = {
  display: "flex",
  flexDirection: "column",
  minHeight: "80vh"
};

export const wrapper = {
  py: 0,
  flex: 1,
  display: "grid",
  gridTemplateColumns: "320px 1fr",
  minHeight: "calc(100vh - 81px)",
  [media.tablet]: {
    display: "block"
  },
  maxWidth: 1500,
  margin: "auto",
  width: "100%"
};
