export const link = {
  margin: "5px 0",
  display: "block",
  color: "sidebar.navGroup",
  textDecoration: "none",
  fontSize: 2,
  "&.active": {
    color: "sidebar.navLinkActive"
  }
};
export const smallLink = {
  ...link,
  ml: 3,
  fontSize: 1,
  position: "relative",
  color: "sidebar.tocLink",
  "&::before": {
    content: '""',
    position: "absolute",
    display: "block",
    top: "50%",
    transform: "translate(-100%,-50%)",
    left: -2.8,
    height: 6,
    backgroundColor: "primary",
    transition: "all 200ms ease 0s",
    width: 6,
    borderRadius: "50%",
    opacity: 0
  },
  "&.active-sublink": {
    color: "sidebar.tocLinkActive"
  },
  "&.active-sublink::before": {
    opacity: 1,
    transform: "translate(0,-50%)"
  }
};
