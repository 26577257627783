/** @jsx jsx */
import { jsx } from "theme-ui";
import { theme, useConfig, ComponentsProvider } from "docz";
import { Styled, ThemeProvider } from "theme-ui";
import defaultTheme from "~theme";
import components from "~components";
import { Header } from "../components/Header";
import Information from "../components/Information";

const Theme = ({ children }) => {
  const config = useConfig();
  return (
    <ThemeProvider theme={config.themeConfig} components={components}>
      <ComponentsProvider components={{ ...components, Header: Header, Information: Information }}>
        <Styled.root>{children}</Styled.root>
      </ComponentsProvider>
    </ThemeProvider>
  );
};

export default theme(defaultTheme)(Theme);
