export const wrapper = {
  my: 3,
  marginBottom: 4,
}

export const sublinkWrapper = {
}

export const title = {
  mb: 1,
  fontSize: 1,
  fontWeight: 800,
  color: 'sidebar.navGroup',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textTransform: "uppercase",
  padding: "0.35rem 0.75rem",
}

export const chevron = ({ active }) => ({
  ml: 1,
  flexShrink: 0,
  alignSelf: 'baseline',
  transform: `rotateX(${active ? 180 : 0}deg)`,
  transformOrigin: 'center',
  transition: 'transform .3s ease-in-out',
})
