export const wrapper = {
  mb: 2.5,
  display: "inline-flex",
  alignItems: "center",
  border: "1px solid #dee5e8",
  padding: "8px 10px",
  borderRadius: 4,
  width: "-webkit-fill-available",
};

export const input = {
  outline: "none",
  background: "none",
  border: "none",
  color: "text",
  fontSize: 1
};

export const icon = {
  color: "border",
  mr: 2
};
