/** @jsx jsx */
import { jsx, Box, Flex, useColorMode } from "theme-ui";
import { useConfig } from "docz";
import { Logo } from "../../gatsby-theme-docz/components/Logo/index";
import * as styles from "./styles";

export const Header = props => {
  const { onOpen } = props;
  const {
    repository,
    themeConfig: { showDarkModeSwitch }
  } = useConfig();
  // const { edit = true, ...doc } = useCurrentDoc();
  const [colorMode, setColorMode] = useColorMode();

  const toggleColorMode = () => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  };

  return (
    <div sx={styles.wrapper} data-testid="header">
      <Box sx={styles.menuIcon}>
        <button sx={styles.menuButton} onClick={onOpen}></button>
      </Box>
      <div sx={styles.innerContainer}>
        <Logo />
        <Flex>
          {repository && (
            <Box sx={{ mr: 2 }}>
              <a href={repository} sx={styles.headerButton} target="_blank" rel="noopener noreferrer">
                Repo
              </a>
            </Box>
          )}
          {showDarkModeSwitch && (
            <button
              sx={styles.headerButton}
              onClick={toggleColorMode}
              aria-label={`Switch to ${colorMode} mode`}
            ></button>
          )}
          <Box sx={{ mr: 2 }}>
            <a href="https://livesession.io/help/" target="_blank" rel="noopener noreferrer">
              Helpcenter
            </a>
          </Box>
        </Flex>
      </div>
      <div sx={styles.innerContainer}>
        <div style={{ height: 38, borderBottom: "1px solid #dee5e8", backgroundColor: "#f6f7f8" }}>TEST</div>
      </div>
    </div>
  );
};
