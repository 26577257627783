import * as mixins from "~utils/mixins";
import { media } from "~theme/breakpoints";

export const wrapper = {
  bg: "header.bg",
  position: "relative",
  zIndex: 1,
  borderBottom: t => `1px solid #dee5e8`
};

export const innerContainer = {
  ...mixins.centerAlign,
  px: 4,
  position: "relative",
  justifyContent: "space-between",
  height: 72,
  maxWidth: 1500,
  boxSizing: "border-box",
  margin: "auto",
  [media.tablet]: {
    px: 3
  }
};

export const menuIcon = {
  display: "none",
  position: "absolute",
  top: "calc(100% + 15px)",
  left: 30,
  width: 30,
  [media.tablet]: {
    display: "block"
  }
};

export const menuButton = {
  ...mixins.ghostButton,
  color: "header.text",
  opacity: 0.5,
  cursor: "pointer",
  width: "100%",
  height: 24,
  backgroundColor: "#ddd"
};

export const headerButton = {
  ...mixins.centerAlign,
  outline: "none",
  p: "12px",
  border: "none",
  borderRadius: 9999,
  bg: "header.button.bg",
  color: "header.button.color",
  fontSize: 0,
  fontWeight: 600,
  cursor: "pointer"
};

export const editButton = {
  ...mixins.centerAlign,
  position: "absolute",
  bottom: -40,
  right: 30,
  bg: "transparent",
  color: "muted",
  fontSize: 1,
  textDecoration: "none",
  borderRadius: "radius"
};
