/** @jsx jsx */
import { useState } from "react";
import { jsx, Flex, useColorMode } from "theme-ui";
import styled, { keyframes } from "styled-components";
// import { useConfig, useCurrentDoc } from "docz";
import { Logo } from "../Logo/index";
import * as styles from "./styles";

const btnAnimation = keyframes`
0%{
 opacity:1;
}
30%{
  opacity:0;
}
60%{
  opacity:0;
}
100%{
  opacity:1;
}
`;

const SubHeader = styled.div`
  height: 42px;
  border-top: 1px solid #dee5e8;
  background-color: #f6f7f8;
  @media (max-width: 768px) {
    display: none;
  }
  & > div {
    max-width: 1500px;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        display: flex;
        height: 40px;
        border-bottom: 3px solid transparent;
        justify-content: center;
        font-size: 14px;
        align-items: center;
        margin-right: 0.5rem;
        padding: 0 0.75rem;
        a {
          color: inherit;
          text-decoration: inherit;
        }

        &.active {
          border-bottom: 3px solid #0446ca;
          font-weight: 600;
        }
      }
    }
  }
`;

const OtherLinks = styled.div`
  display: none;
  align-items: center;
  & a {
    display: block;
    font-size: 15px;
    text-decoration: none;
    color: var(--theme-ui-colors-header-text);
    margin: 0 1rem;

    &:hover {
      color: #0446ca;
    }

    &.btn {
      margin-left: 3rem;
      margin-right: 0;
      border: 1px solid;
      padding: 0.25rem 0.75rem;
      border-radius: 4px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  & button {
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 12px;
    padding: 0 8px;
    position: relative;
    overflow: hidden;
    border: none;
    background-color: transparent;
    outline: none;
    &.active {
      animation: ${btnAnimation} 0.5s ease both;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Header = (props) => {
  const { onOpen, isOpen } = props;
  // const {
  //   repository,
  //   themeConfig: { showDarkModeSwitch, showMarkdownEditButton }
  // } = useConfig();
  // const { edit = true, ...doc } = useCurrentDoc();
  const [colorMode] = useColorMode();

  // const toggleColorMode = () => {
  //   setColorMode(colorMode === "light" ? "dark" : "light");
  // };

  const [isBtnActive, setActiveBtn] = useState(false);
  const btnText = isOpen ? "CLOSE" : "MENU";

  const openMenu = () => {
    setActiveBtn(true);
    setTimeout(() => {
      onOpen();
    }, 200);
    setTimeout(() => {
      setActiveBtn(false);
    }, 500);
  };

  return (
    <div sx={styles.wrapper} data-testid="header" style={{ backgroundColor: colorMode === "light" ? "#fff" : "" }}>
      <div sx={styles.innerContainer}>
        <Logo />
        <Flex sx={{ alignItems: "center" }}>
          <OtherLinks>
            <a href="https://livesession.statuspal.io/" target="_blank" rel="noopener noreferrer">
              API status <span className="sp-status"></span>
            </a>
            <a href="https://livesession.io/blog/" target="_blank" rel="noopener noreferrer">
              Blog
            </a>
            <a href="https://app.livesession.io/signin/" target="_blank" rel="noopener noreferrer">
              Log in
            </a>
            <a href="https://livesession.io/" target="_blank" rel="noopener noreferrer">
              Back to LiveSession
            </a>
          </OtherLinks>
          <ButtonContainer>
            <button onClick={() => openMenu()} className={isBtnActive && "active"}>
              {btnText}
            </button>
          </ButtonContainer>
          {/* {showDarkModeSwitch && (
            <button
              sx={styles.headerButton}
              onClick={toggleColorMode}
              aria-label={`Switch to ${colorMode} mode`}
            ></button>
          )} */}
        </Flex>
        {/* {showMarkdownEditButton && edit && doc.link && (
          <a sx={styles.editButton} href={doc.link} target="_blank" rel="noopener noreferrer">
            <Box sx={{ pl: 2 }}>Edit page</Box>
          </a>
        )} */}
      </div>
      <SubHeader>
        <div>
          <Flex>
            <ul>
              <li className="active">
                <a href="/">API & Webhooks Reference</a>
              </li>
              <li>
                <a href="https://livesession.io/integrations/" target="_blank" rel="noopener noreferrer">
                  Integrations
                </a>
              </li>
              <li>
                <a href="https://livesession.io/help/" target="_blank" rel="noopener noreferrer">
                  Help Center
                </a>
              </li>
            </ul>
          </Flex>
        </div>
      </SubHeader>
    </div>
  );
};
