/** @jsx jsx */
/* eslint react/jsx-key: 0 */
import { useState } from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import { jsx, Styled } from "theme-ui";
import styled from "styled-components";
import copy from "../../../icons/copy.svg";
import copied from "../../../icons/copied.svg";
import { usePrismTheme } from "~utils/theme";

const CopyButton = styled.button`
  position: absolute;
  z-index: 20;
  right: 8px;
  top: 8px;
  align-self: flex-end;
  background-color: rgb(246, 248, 250);
  border-radius: 4px;
  border: none;
  padding: 14px;
  opacity: 0;
  margin-top: 8px;
  margin-right: 8px;
  background-image: url(${copy});
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1;
  }
  &.copied {
    background-image: url(${copied});
  }
  .tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .tooltiptext.active {
    visibility: visible;
    opacity: 1;
  }
`;

const CodeSection = styled.div`
  position: relative;
  &:hover {
    ${CopyButton} {
      opacity: 1;
    }
  }
`;

export const Code = ({ children, className: outerClassName }) => {
  const [language] = outerClassName ? outerClassName.replace(/language-/, "").split(" ") : ["text"];
  const theme = usePrismTheme();
  const [isCopied, setCopy] = useState(false);

  const handleCopyBtn = () => {
    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = children;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
        setCopy(!isCopied);
        setTimeout(() => setCopy(false), 1200);
      }
    }
  };

  const randomNumber = (Math.random() * 1).toString().slice(2);

  return (
    <CodeSection>
      <input type="text" hidden id={`value${randomNumber}`} defaultValue={children} />
      <Highlight {...defaultProps} code={children.trim()} language={language} theme={theme}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Styled.pre
            className={`${outerClassName || ""} ${className}`}
            style={{ ...style, overflowX: "auto" }}
            data-testid="code"
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })} style={{ width: "90%" }}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} sx={{ display: "inline-block" }} />
                ))}
              </div>
            ))}
          </Styled.pre>
        )}
      </Highlight>
      <CopyButton onClick={handleCopyBtn} className={isCopied && "copied"}>
        <span className={["tooltiptext", isCopied && "active"].join(" ")}>Copied!</span>
      </CopyButton>
    </CodeSection>
  );
};
