/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { useDocs, useCurrentDoc } from "docz";
import { get } from "lodash/fp";
import styled from "styled-components";

import * as styles from "./styles";

const getHeadings = (route, docs) => {
  const doc = docs.find(doc => doc.route === route);
  const headings = get("headings", doc);
  return headings ? headings.filter(heading => heading.depth === 2) : [];
};

const getCurrentHash = () => {
  if (typeof window === "undefined") {
    return "";
  }
  return window.location ? decodeURI(window.location.hash) : "";
};

const StyledLink = styled(Link)`
  font-weight: 300;
  font-size: 14px !important;

  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  color: var(--theme-ui-colors-sidebar-navGroup, #888);
  padding: 0.35rem 0.75rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: 0.2s;
  border-radius: 2px;

  &.active {
    color: white !important;
    background-color: #0446ca;
    font-weight: 600;
  }

  &:hover:not(.active) {
    color: #222;
    text-decoration: underline;
  }
`;

export const NavLink = React.forwardRef(({ item, ...props }, ref) => {
  const docs = useDocs();
  const to = item.route;
  const headings = docs && getHeadings(to, docs);
  const current = useCurrentDoc();
  const isCurrent = item.route === current.route;
  const showHeadings = isCurrent && headings && headings.length > 0;
  const currentHash = getCurrentHash();

  return (
    <React.Fragment>
      <StyledLink {...props} to={to} sx={styles.link} activeClassName="active" ref={ref} />
      {showHeadings &&
        headings.map(heading => (
          <StyledLink
            key={heading.slug}
            to={`${to}#${heading.slug}`}
            sx={styles.smallLink}
            className={currentHash === `#${heading.slug}` ? "active-sublink" : ""}
          >
            {heading.value}
          </StyledLink>
        ))}
    </React.Fragment>
  );
});
